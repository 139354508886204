import { Component, OnInit, inject } from '@angular/core';
import { NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SelectComponent } from '../../ui/select/select.component';
import { AuthService } from '../../../services/auth.service';
import { StorageService } from '../../../services/storage.service';
import { HttpClient } from '@angular/common/http';
import { MenuModule } from 'primeng/menu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { SkeletonModule } from 'primeng/skeleton';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgForOf,
    NgOptimizedImage,
    SelectComponent,
    RouterLinkActive,
    RouterLink,
    NgIf,
    MenuModule,
    ConfirmDialogModule,
    SkeletonModule,
    RippleModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  private http = inject(HttpClient);
  private authService: AuthService = inject(AuthService);
  private storageService: StorageService = inject(StorageService);
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  public currentApplicationVersion = environment.app_version;
  public showGlfLogo: boolean = false;
  public readonly menuItems: Array<{ title: string; path: string }> = [
    {
      title: 'Dashboard',
      path: 'dashboard',
    },
    {
      title: 'Lead Center',
      path: 'lead-center',
    },
    {
      title: 'KPIs',
      path: 'kpi',
    },
    {
      title: 'Business Variables',
      path: 'settings',
    },
  ];
  public dropDownItems: Array<{ value: string; label: string, automatic_lead_qualification_start: string }> = [];

  public userName: string = '';

  public userMenuItems: any = [];

  public activeItem: any = {};

  public isAdminClass: boolean = false;

  constructor() {
    if (window.location.hostname.includes('growlawfirm')) {
      this.showGlfLogo = true;
    }
    this.isAdminClass = this.storageService.isAdmin;
    setInterval(
      () => {
        this.authService.refreshToken()?.subscribe(() => {});
      },
      60000 * 1000 * 2,
    );
  }

  async onSiteChange(event: any) {
    this.storageService.$activePlatform.next(event);
    localStorage.setItem('platform', event);
    await this.storageService.getCurrentPlatformData();
    this.storageService.$changePlatform.next(event);
    if (this.checkActiveGrid(this.storageService.gridApi)) {
      this.storageService.gridApi?.refreshServerSide();
    }
  }

  private checkActiveGrid(gridApi: any): boolean {
    return !!(gridApi && gridApi.destroyCalled === false);
  }

  public logout() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to log out?',
      header: 'Confirmation',
      // icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.authService.logout();
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
    });
    // if (confirm('Are you sure you want to log out?')) {
    //   this.authService.logout();
    // }
  }
  ngOnInit() {
    this.storageService.$userData.subscribe((userData) => {
      if (userData && userData.name) {
        const nameArray = userData.name
          ?.split(' ')
          ?.map((item: string) => item.toUpperCase()[0]);
        this.userName = nameArray?.slice(0, 2).join('');
        this.userMenuItems = [
          {
            label: userData.name,
            items: [
              {
                label: 'Logout',
                icon: 'pi pi-refresh',
                command: () => {
                  this.logout();
                },
              },
            ],
          },
        ];
      } else if (userData && userData.full_name) {
        this.userMenuItems = [
          {
            label: userData.full_name,
            items: [
              {
                label: 'Logout',
                icon: 'pi pi-refresh',
                command: () => {
                  this.logout();
                },
              },
            ],
          },
        ];
      }
    });
    this.storageService.$platforms.subscribe((platforms) => {
      if (platforms) {
        let platformDropdownList = [];
        for (const platform of platforms) {
          let demoABBR = platform?.project?.abbr_l;
          if (platform._id === '6720e35dfd724b6e228b1486') {
            demoABBR = 'SMP';
          }
          platformDropdownList.push({
            value: platform._id,
            label: platform.url,
            abbr_l: demoABBR,
            automatic_lead_qualification_start: platform.automatic_lead_qualification_start
          });
        }

        this.sortByDate(platformDropdownList);
        this.dropDownItems = platformDropdownList;

        const activePlatform = localStorage.getItem('platform');
        if (activePlatform) {
          this.storageService.$activePlatform.next(activePlatform);
          const platform = platforms.find(
            (platform: any) => platform._id === activePlatform,
          );
          this.activeItem = { label: platform.url, value: platform._id };
          localStorage.setItem('platform', platform._id);
        } else {
          this.activeItem = this.dropDownItems[0];
          localStorage.setItem('platform', this.dropDownItems[0].value);
          this.storageService.$activePlatform.next(this.dropDownItems[0].value);
        }
      }
    });
  }

  private sortByDate(arr: any) {
    arr.sort((a: any, b: any) => {
      if (!a.automatic_lead_qualification_start && b.automatic_lead_qualification_start) {
        return 1;
      }
      if (a.automatic_lead_qualification_start && !b.automatic_lead_qualification_start) {
        return -1;
      }
      if (!a.automatic_lead_qualification_start && !b.automatic_lead_qualification_start) {
        return 0;
      }
      return +new Date(a.automatic_lead_qualification_start) - +new Date(b.automatic_lead_qualification_start);
    });

    return arr;
  }
}
