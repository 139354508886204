import { animation, style, animate, trigger, transition, useAnimation } from '@angular/animations';
import * as i2 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, booleanAttribute, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Input, Output, ContentChild, ViewChild, ContentChildren, NgModule } from '@angular/core';
import * as i1 from 'primeng/api';
import { ConfirmEventType, TranslationKeys, Footer, PrimeTemplate, SharedModule } from 'primeng/api';
import * as i3 from 'primeng/button';
import { ButtonModule } from 'primeng/button';
import { DomHandler } from 'primeng/dom';
import { CheckIcon } from 'primeng/icons/check';
import { TimesIcon } from 'primeng/icons/times';
import * as i4 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import { UniqueComponentId, ZIndexUtils } from 'primeng/utils';
const _c0 = ["content"];
const _c1 = [[["p-footer"]]];
const _c2 = ["p-footer"];
const _c3 = a0 => ({
  "p-dialog p-confirm-dialog p-component": true,
  "p-dialog-rtl": a0
});
const _c4 = (a0, a1) => ({
  transform: a0,
  transition: a1
});
const _c5 = a0 => ({
  value: "visible",
  params: a0
});
const _c6 = a0 => ({
  $implicit: a0
});
const _c7 = () => ({
  "p-dialog-header-icon p-dialog-header-close p-link": true
});
function ConfirmDialog_div_0_div_1_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ConfirmDialog_div_0_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_container_1_ng_container_1_Template, 1, 0, "ng-container", 7);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.headlessTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c6, ctx_r1.confirmation));
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_div_0_ng_container_1_Template, 1, 0, "ng-container", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.headerTemplate);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 19);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("id", ctx_r1.ariaLabelledBy);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.option("header"));
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_1_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 20);
    i0.ɵɵlistener("click", function ConfirmDialog_div_0_div_1_ng_template_2_div_1_button_3_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r1.close($event));
    })("keydown.enter", function ConfirmDialog_div_0_div_1_ng_template_2_div_1_button_3_Template_button_keydown_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r1.close($event));
    });
    i0.ɵɵelement(1, "TimesIcon");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction0(2, _c7));
    i0.ɵɵattribute("aria-label", ctx_r1.closeAriaLabel);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_div_1_span_1_Template, 2, 2, "span", 16);
    i0.ɵɵelementStart(2, "div", 17);
    i0.ɵɵtemplate(3, ConfirmDialog_div_0_div_1_ng_template_2_div_1_button_3_Template, 2, 3, "button", 18);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.option("header"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.closable);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_i_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 3);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵclassMap(ctx_r1.option("icon"));
    i0.ɵɵproperty("ngClass", "p-confirm-dialog-icon");
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_ng_container_5_1_ng_template_0_Template(rf, ctx) {}
function ConfirmDialog_div_0_div_1_ng_template_2_ng_container_5_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ConfirmDialog_div_0_div_1_ng_template_2_ng_container_5_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_ng_container_5_1_Template, 1, 0, null, 15);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.iconTemplate);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_span_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 21);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("innerHTML", ctx_r1.option("message"), i0.ɵɵsanitizeHtml);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_ng_container_7_1_ng_template_0_Template(rf, ctx) {}
function ConfirmDialog_div_0_div_1_ng_template_2_ng_container_7_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ConfirmDialog_div_0_div_1_ng_template_2_ng_container_7_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_ng_container_7_1_Template, 1, 0, null, 7);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.messageTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c6, ctx_r1.confirmation));
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_8_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 22);
    i0.ɵɵprojection(1);
    i0.ɵɵtemplate(2, ConfirmDialog_div_0_div_1_ng_template_2_div_8_ng_container_2_Template, 1, 0, "ng-container", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.footerTemplate);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_ng_container_1_i_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(7);
    i0.ɵɵclassMap(ctx_r1.option("rejectIcon"));
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_ng_container_1_TimesIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "TimesIcon", 28);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-button-icon-left");
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_ng_container_1_i_1_Template, 1, 2, "i", 26)(2, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_ng_container_1_TimesIcon_2_Template, 1, 1, "TimesIcon", 27);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(6);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.option("rejectIcon"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.option("rejectIcon"));
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_span_2_1_ng_template_0_Template(rf, ctx) {}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 29);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_span_2_1_Template, 1, 0, null, 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(6);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.rejectIconTemplate);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 24);
    i0.ɵɵlistener("click", function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r1.reject());
    });
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_ng_container_1_Template, 3, 2, "ng-container", 11)(2, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_span_2_Template, 2, 1, "span", 25);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵclassMap(ctx_r1.option("rejectButtonStyleClass"));
    i0.ɵɵproperty("label", ctx_r1.rejectButtonLabel)("ngClass", "p-confirm-dialog-reject");
    i0.ɵɵattribute("aria-label", ctx_r1.rejectAriaLabel);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.rejectIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.rejectIconTemplate);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_ng_container_1_i_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(7);
    i0.ɵɵclassMap(ctx_r1.option("acceptIcon"));
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_ng_container_1_CheckIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "CheckIcon", 28);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-button-icon-left");
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_ng_container_1_i_1_Template, 1, 2, "i", 26)(2, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_ng_container_1_CheckIcon_2_Template, 1, 1, "CheckIcon", 27);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(6);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.option("acceptIcon"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.option("acceptIcon"));
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_span_2_1_ng_template_0_Template(rf, ctx) {}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 29);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_span_2_1_Template, 1, 0, null, 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(6);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.acceptIconTemplate);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 24);
    i0.ɵɵlistener("click", function ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r1.accept());
    });
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_ng_container_1_Template, 3, 2, "ng-container", 11)(2, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_span_2_Template, 2, 1, "span", 25);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵclassMap(ctx_r1.option("acceptButtonStyleClass"));
    i0.ɵɵproperty("label", ctx_r1.acceptButtonLabel)("ngClass", "p-confirm-dialog-accept");
    i0.ɵɵattribute("aria-label", ctx_r1.acceptAriaLabel);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.acceptIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.acceptIconTemplate);
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_div_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 22);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_1_Template, 3, 7, "button", 23)(2, ConfirmDialog_div_0_div_1_ng_template_2_div_9_button_2_Template, 3, 7, "button", 23);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.option("rejectVisible"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.option("acceptVisible"));
  }
}
function ConfirmDialog_div_0_div_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ConfirmDialog_div_0_div_1_ng_template_2_div_0_Template, 2, 1, "div", 8)(1, ConfirmDialog_div_0_div_1_ng_template_2_div_1_Template, 4, 2, "div", 8);
    i0.ɵɵelementStart(2, "div", 9, 1);
    i0.ɵɵtemplate(4, ConfirmDialog_div_0_div_1_ng_template_2_i_4_Template, 1, 3, "i", 10)(5, ConfirmDialog_div_0_div_1_ng_template_2_ng_container_5_Template, 2, 1, "ng-container", 11)(6, ConfirmDialog_div_0_div_1_ng_template_2_span_6_Template, 1, 1, "span", 12)(7, ConfirmDialog_div_0_div_1_ng_template_2_ng_container_7_Template, 2, 4, "ng-container", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, ConfirmDialog_div_0_div_1_ng_template_2_div_8_Template, 3, 1, "div", 13)(9, ConfirmDialog_div_0_div_1_ng_template_2_div_9_Template, 3, 2, "div", 13);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngIf", ctx_r1.headerTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.headerTemplate);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", !ctx_r1.iconTemplate && ctx_r1.option("icon"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.iconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.messageTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.messageTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.footer || ctx_r1.footerTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.footer && !ctx_r1.footerTemplate);
  }
}
function ConfirmDialog_div_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵlistener("@animation.start", function ConfirmDialog_div_0_div_1_Template_div_animation_animation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onAnimationStart($event));
    })("@animation.done", function ConfirmDialog_div_0_div_1_Template_div_animation_animation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onAnimationEnd($event));
    });
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_ng_container_1_Template, 2, 4, "ng-container", 6)(2, ConfirmDialog_div_0_div_1_ng_template_2_Template, 10, 8, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const notHeadless_r6 = i0.ɵɵreference(3);
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.styleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(9, _c3, ctx_r1.rtl))("ngStyle", ctx_r1.style)("@animation", i0.ɵɵpureFunction1(14, _c5, i0.ɵɵpureFunction2(11, _c4, ctx_r1.transformOptions, ctx_r1.transitionOptions)));
    i0.ɵɵattribute("aria-labelledby", ctx_r1.ariaLabelledBy)("aria-modal", true);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.headlessTemplate)("ngIfElse", notHeadless_r6);
  }
}
function ConfirmDialog_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtemplate(1, ConfirmDialog_div_0_div_1_Template, 4, 16, "div", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.maskStyleClass);
    i0.ɵɵproperty("ngClass", ctx_r1.getMaskClass());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.visible);
  }
}
const showAnimation = animation([style({
  transform: '{{transform}}',
  opacity: 0
}), animate('{{transition}}', style({
  transform: 'none',
  opacity: 1
}))]);
const hideAnimation = animation([animate('{{transition}}', style({
  transform: '{{transform}}',
  opacity: 0
}))]);
/**
 * ConfirmDialog uses a Dialog UI that is integrated with the Confirmation API.
 * @group Components
 */
class ConfirmDialog {
  el;
  renderer;
  confirmationService;
  zone;
  cd;
  config;
  document;
  /**
   * Title text of the dialog.
   * @group Props
   */
  header;
  /**
   * Icon to display next to message.
   * @group Props
   */
  icon;
  /**
   * Message of the confirmation.
   * @group Props
   */
  message;
  /**
   * Inline style of the element.
   * @group Props
   */
  get style() {
    return this._style;
  }
  set style(value) {
    this._style = value;
    this.cd.markForCheck();
  }
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Specify the CSS class(es) for styling the mask element
   * @group Props
   */
  maskStyleClass;
  /**
   * Icon of the accept button.
   * @group Props
   */
  acceptIcon;
  /**
   * Label of the accept button.
   * @group Props
   */
  acceptLabel;
  /**
   * Defines a string that labels the close button for accessibility.
   * @group Props
   */
  closeAriaLabel;
  /**
   * Defines a string that labels the accept button for accessibility.
   * @group Props
   */
  acceptAriaLabel;
  /**
   * Visibility of the accept button.
   * @group Props
   */
  acceptVisible = true;
  /**
   * Icon of the reject button.
   * @group Props
   */
  rejectIcon;
  /**
   * Label of the reject button.
   * @group Props
   */
  rejectLabel;
  /**
   * Defines a string that labels the reject button for accessibility.
   * @group Props
   */
  rejectAriaLabel;
  /**
   * Visibility of the reject button.
   * @group Props
   */
  rejectVisible = true;
  /**
   * Style class of the accept button.
   * @group Props
   */
  acceptButtonStyleClass;
  /**
   * Style class of the reject button.
   * @group Props
   */
  rejectButtonStyleClass;
  /**
   * Specifies if pressing escape key should hide the dialog.
   * @group Props
   */
  closeOnEscape = true;
  /**
   * Specifies if clicking the modal background should hide the dialog.
   * @group Props
   */
  dismissableMask;
  /**
   * Determines whether scrolling behavior should be blocked within the component.
   * @group Props
   */
  blockScroll = true;
  /**
   * When enabled dialog is displayed in RTL direction.
   * @group Props
   */
  rtl = false;
  /**
   * Adds a close icon to the header to hide the dialog.
   * @group Props
   */
  closable = true;
  /**
   *  Target element to attach the dialog, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  appendTo;
  /**
   * Optional key to match the key of confirm object, necessary to use when component tree has multiple confirm dialogs.
   * @group Props
   */
  key;
  /**
   * Whether to automatically manage layering.
   * @group Props
   */
  autoZIndex = true;
  /**
   * Base zIndex value to use in layering.
   * @group Props
   */
  baseZIndex = 0;
  /**
   * Transition options of the animation.
   * @group Props
   */
  transitionOptions = '150ms cubic-bezier(0, 0, 0.2, 1)';
  /**
   * When enabled, can only focus on elements inside the confirm dialog.
   * @group Props
   */
  focusTrap = true;
  /**
   * Element to receive the focus when the dialog gets visible.
   * @group Props
   */
  defaultFocus = 'accept';
  /**
   * Object literal to define widths per screen size.
   * @group Props
   */
  breakpoints;
  /**
   * Current visible state as a boolean.
   * @group Props
   */
  get visible() {
    return this._visible;
  }
  set visible(value) {
    this._visible = value;
    if (this._visible && !this.maskVisible) {
      this.maskVisible = true;
    }
    this.cd.markForCheck();
  }
  /**
   *  Allows getting the position of the component.
   * @group Props
   */
  get position() {
    return this._position;
  }
  set position(value) {
    this._position = value;
    switch (value) {
      case 'top-left':
      case 'bottom-left':
      case 'left':
        this.transformOptions = 'translate3d(-100%, 0px, 0px)';
        break;
      case 'top-right':
      case 'bottom-right':
      case 'right':
        this.transformOptions = 'translate3d(100%, 0px, 0px)';
        break;
      case 'bottom':
        this.transformOptions = 'translate3d(0px, 100%, 0px)';
        break;
      case 'top':
        this.transformOptions = 'translate3d(0px, -100%, 0px)';
        break;
      default:
        this.transformOptions = 'scale(0.7)';
        break;
    }
  }
  /**
   * Callback to invoke when dialog is hidden.
   * @param {ConfirmEventType} enum - Custom confirm event.
   * @group Emits
   */
  onHide = new EventEmitter();
  footer;
  contentViewChild;
  templates;
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
        case 'message':
          this.messageTemplate = item.template;
          break;
        case 'icon':
          this.iconTemplate = item.template;
          break;
        case 'rejecticon':
          this.rejectIconTemplate = item.template;
          break;
        case 'accepticon':
          this.acceptIconTemplate = item.template;
          break;
        case 'headless':
          this.headlessTemplate = item.template;
          break;
      }
    });
  }
  headerTemplate;
  footerTemplate;
  rejectIconTemplate;
  acceptIconTemplate;
  messageTemplate;
  iconTemplate;
  headlessTemplate;
  confirmation;
  _visible;
  _style;
  maskVisible;
  documentEscapeListener;
  container;
  wrapper;
  contentContainer;
  subscription;
  maskClickListener;
  preWidth;
  _position = 'center';
  transformOptions = 'scale(0.7)';
  styleElement;
  id = UniqueComponentId();
  ariaLabelledBy = this.getAriaLabelledBy();
  confirmationOptions;
  translationSubscription;
  constructor(el, renderer, confirmationService, zone, cd, config, document) {
    this.el = el;
    this.renderer = renderer;
    this.confirmationService = confirmationService;
    this.zone = zone;
    this.cd = cd;
    this.config = config;
    this.document = document;
    this.subscription = this.confirmationService.requireConfirmation$.subscribe(confirmation => {
      if (!confirmation) {
        this.hide();
        return;
      }
      if (confirmation.key === this.key) {
        this.confirmation = confirmation;
        this.confirmationOptions = {
          message: this.confirmation.message || this.message,
          icon: this.confirmation.icon || this.icon,
          header: this.confirmation.header || this.header,
          rejectVisible: this.confirmation.rejectVisible == null ? this.rejectVisible : this.confirmation.rejectVisible,
          acceptVisible: this.confirmation.acceptVisible == null ? this.acceptVisible : this.confirmation.acceptVisible,
          acceptLabel: this.confirmation.acceptLabel || this.acceptLabel,
          rejectLabel: this.confirmation.rejectLabel || this.rejectLabel,
          acceptIcon: this.confirmation.acceptIcon || this.acceptIcon,
          rejectIcon: this.confirmation.rejectIcon || this.rejectIcon,
          acceptButtonStyleClass: this.confirmation.acceptButtonStyleClass || this.acceptButtonStyleClass,
          rejectButtonStyleClass: this.confirmation.rejectButtonStyleClass || this.rejectButtonStyleClass,
          defaultFocus: this.confirmation.defaultFocus || this.defaultFocus,
          blockScroll: this.confirmation.blockScroll === false || this.confirmation.blockScroll === true ? this.confirmation.blockScroll : this.blockScroll,
          closeOnEscape: this.confirmation.closeOnEscape === false || this.confirmation.closeOnEscape === true ? this.confirmation.closeOnEscape : this.closeOnEscape,
          dismissableMask: this.confirmation.dismissableMask === false || this.confirmation.dismissableMask === true ? this.confirmation.dismissableMask : this.dismissableMask
        };
        if (this.confirmation.accept) {
          this.confirmation.acceptEvent = new EventEmitter();
          this.confirmation.acceptEvent.subscribe(this.confirmation.accept);
        }
        if (this.confirmation.reject) {
          this.confirmation.rejectEvent = new EventEmitter();
          this.confirmation.rejectEvent.subscribe(this.confirmation.reject);
        }
        this.visible = true;
      }
    });
  }
  ngOnInit() {
    if (this.breakpoints) {
      this.createStyle();
    }
    this.translationSubscription = this.config.translationObserver.subscribe(() => {
      if (this.visible) {
        this.cd.markForCheck();
      }
    });
  }
  getAriaLabelledBy() {
    return this.header !== null ? UniqueComponentId() + '_header' : null;
  }
  option(name) {
    const source = this.confirmationOptions || this;
    if (source.hasOwnProperty(name)) {
      return source[name];
    }
    return undefined;
  }
  onAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.wrapper = this.container?.parentElement;
        this.contentContainer = DomHandler.findSingle(this.container, '.p-dialog-content');
        this.container?.setAttribute(this.id, '');
        this.appendContainer();
        this.moveOnTop();
        this.bindGlobalListeners();
        this.enableModality();
        const element = this.getElementToFocus();
        if (element) {
          element.focus();
        }
        break;
    }
  }
  onAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        this.onOverlayHide();
        break;
    }
  }
  getElementToFocus() {
    switch (this.option('defaultFocus')) {
      case 'accept':
        return DomHandler.findSingle(this.container, '.p-confirm-dialog-accept');
      case 'reject':
        return DomHandler.findSingle(this.container, '.p-confirm-dialog-reject');
      case 'close':
        return DomHandler.findSingle(this.container, '.p-dialog-header-close');
      case 'none':
        return null;
      //backward compatibility
      default:
        return DomHandler.findSingle(this.container, '.p-confirm-dialog-accept');
    }
  }
  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body') this.document.body.appendChild(this.wrapper);else DomHandler.appendChild(this.wrapper, this.appendTo);
    }
  }
  restoreAppend() {
    if (this.wrapper && this.appendTo) {
      this.el.nativeElement.appendChild(this.wrapper);
    }
  }
  enableModality() {
    if (this.option('blockScroll')) {
      DomHandler.addClass(this.document.body, 'p-overflow-hidden');
    }
    if (this.option('dismissableMask')) {
      this.maskClickListener = this.renderer.listen(this.wrapper, 'mousedown', event => {
        if (this.wrapper && this.wrapper.isSameNode(event.target)) {
          this.close(event);
        }
      });
    }
  }
  disableModality() {
    this.maskVisible = false;
    if (this.option('blockScroll')) {
      DomHandler.removeClass(this.document.body, 'p-overflow-hidden');
    }
    if (this.dismissableMask) {
      this.unbindMaskClickListener();
    }
    if (this.container && !this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }
  createStyle() {
    if (!this.styleElement) {
      this.styleElement = this.document.createElement('style');
      this.styleElement.type = 'text/css';
      DomHandler.setAttribute(this.styleElement, 'nonce', this.config?.csp()?.nonce);
      this.document.head.appendChild(this.styleElement);
      let innerHTML = '';
      for (let breakpoint in this.breakpoints) {
        innerHTML += `
                    @media screen and (max-width: ${breakpoint}) {
                        .p-dialog[${this.id}] {
                            width: ${this.breakpoints[breakpoint]} !important;
                        }
                    }
                `;
      }
      this.styleElement.innerHTML = innerHTML;
    }
  }
  close(event) {
    if (this.confirmation?.rejectEvent) {
      this.confirmation.rejectEvent.emit(ConfirmEventType.CANCEL);
    }
    this.hide(ConfirmEventType.CANCEL);
    event.preventDefault();
  }
  hide(type) {
    this.onHide.emit(type);
    this.visible = false;
    this.confirmation = null;
    this.confirmationOptions = null;
  }
  moveOnTop() {
    if (this.autoZIndex) {
      ZIndexUtils.set('modal', this.container, this.baseZIndex + this.config.zIndex.modal);
      this.wrapper.style.zIndex = String(parseInt(this.container.style.zIndex, 10) - 1);
    }
  }
  getMaskClass() {
    let maskClass = {
      'p-dialog-mask p-component-overlay': true,
      'p-dialog-mask-scrollblocker': this.blockScroll
    };
    maskClass[this.getPositionClass().toString()] = true;
    return maskClass;
  }
  getPositionClass() {
    const positions = ['left', 'right', 'top', 'top-left', 'top-right', 'bottom', 'bottom-left', 'bottom-right'];
    const pos = positions.find(item => item === this.position);
    return pos ? `p-dialog-${pos}` : '';
  }
  bindGlobalListeners() {
    if (this.option('closeOnEscape') && this.closable || this.focusTrap && !this.documentEscapeListener) {
      const documentTarget = this.el ? this.el.nativeElement.ownerDocument : 'document';
      this.documentEscapeListener = this.renderer.listen(documentTarget, 'keydown', event => {
        if (event.which == 27 && this.option('closeOnEscape') && this.closable) {
          if (parseInt(this.container.style.zIndex) === ZIndexUtils.get(this.container) && this.visible) {
            this.close(event);
          }
        }
        if (event.which === 9 && this.focusTrap) {
          event.preventDefault();
          let focusableElements = DomHandler.getFocusableElements(this.container);
          if (focusableElements && focusableElements.length > 0) {
            if (!focusableElements[0].ownerDocument.activeElement) {
              focusableElements[0].focus();
            } else {
              let focusedIndex = focusableElements.indexOf(focusableElements[0].ownerDocument.activeElement);
              if (event.shiftKey) {
                if (focusedIndex == -1 || focusedIndex === 0) focusableElements[focusableElements.length - 1].focus();else focusableElements[focusedIndex - 1].focus();
              } else {
                if (focusedIndex == -1 || focusedIndex === focusableElements.length - 1) focusableElements[0].focus();else focusableElements[focusedIndex + 1].focus();
              }
            }
          }
        }
      });
    }
  }
  unbindGlobalListeners() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }
  }
  unbindMaskClickListener() {
    if (this.maskClickListener) {
      this.maskClickListener();
      this.maskClickListener = null;
    }
  }
  onOverlayHide() {
    if (this.container && this.autoZIndex) {
      ZIndexUtils.clear(this.container);
    }
    this.disableModality();
    this.unbindGlobalListeners();
    this.container = null;
  }
  destroyStyle() {
    if (this.styleElement) {
      this.document.head.removeChild(this.styleElement);
      this.styleElement = null;
    }
  }
  ngOnDestroy() {
    this.restoreAppend();
    this.onOverlayHide();
    this.subscription.unsubscribe();
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
    this.destroyStyle();
  }
  accept() {
    if (this.confirmation && this.confirmation.acceptEvent) {
      this.confirmation.acceptEvent.emit();
    }
    this.hide(ConfirmEventType.ACCEPT);
  }
  reject() {
    if (this.confirmation && this.confirmation.rejectEvent) {
      this.confirmation.rejectEvent.emit(ConfirmEventType.REJECT);
    }
    this.hide(ConfirmEventType.REJECT);
  }
  get acceptButtonLabel() {
    return this.option('acceptLabel') || this.config.getTranslation(TranslationKeys.ACCEPT);
  }
  get rejectButtonLabel() {
    return this.option('rejectLabel') || this.config.getTranslation(TranslationKeys.REJECT);
  }
  static ɵfac = function ConfirmDialog_Factory(t) {
    return new (t || ConfirmDialog)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.ConfirmationService), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.PrimeNGConfig), i0.ɵɵdirectiveInject(DOCUMENT));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ConfirmDialog,
    selectors: [["p-confirmDialog"]],
    contentQueries: function ConfirmDialog_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, Footer, 5);
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footer = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function ConfirmDialog_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentViewChild = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      header: "header",
      icon: "icon",
      message: "message",
      style: "style",
      styleClass: "styleClass",
      maskStyleClass: "maskStyleClass",
      acceptIcon: "acceptIcon",
      acceptLabel: "acceptLabel",
      closeAriaLabel: "closeAriaLabel",
      acceptAriaLabel: "acceptAriaLabel",
      acceptVisible: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "acceptVisible", "acceptVisible", booleanAttribute],
      rejectIcon: "rejectIcon",
      rejectLabel: "rejectLabel",
      rejectAriaLabel: "rejectAriaLabel",
      rejectVisible: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "rejectVisible", "rejectVisible", booleanAttribute],
      acceptButtonStyleClass: "acceptButtonStyleClass",
      rejectButtonStyleClass: "rejectButtonStyleClass",
      closeOnEscape: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "closeOnEscape", "closeOnEscape", booleanAttribute],
      dismissableMask: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "dismissableMask", "dismissableMask", booleanAttribute],
      blockScroll: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "blockScroll", "blockScroll", booleanAttribute],
      rtl: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "rtl", "rtl", booleanAttribute],
      closable: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "closable", "closable", booleanAttribute],
      appendTo: "appendTo",
      key: "key",
      autoZIndex: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "autoZIndex", "autoZIndex", booleanAttribute],
      baseZIndex: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "baseZIndex", "baseZIndex", numberAttribute],
      transitionOptions: "transitionOptions",
      focusTrap: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "focusTrap", "focusTrap", booleanAttribute],
      defaultFocus: "defaultFocus",
      breakpoints: "breakpoints",
      visible: "visible",
      position: "position"
    },
    outputs: {
      onHide: "onHide"
    },
    features: [i0.ɵɵInputTransformsFeature],
    ngContentSelectors: _c2,
    decls: 1,
    vars: 1,
    consts: [["notHeadless", ""], ["content", ""], [3, "class", "ngClass", 4, "ngIf"], [3, "ngClass"], ["role", "alertdialog", 3, "ngClass", "ngStyle", "class", 4, "ngIf"], ["role", "alertdialog", 3, "ngClass", "ngStyle"], [4, "ngIf", "ngIfElse"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["class", "p-dialog-header", 4, "ngIf"], [1, "p-dialog-content"], [3, "ngClass", "class", 4, "ngIf"], [4, "ngIf"], ["class", "p-confirm-dialog-message", 3, "innerHTML", 4, "ngIf"], ["class", "p-dialog-footer", 4, "ngIf"], [1, "p-dialog-header"], [4, "ngTemplateOutlet"], ["class", "p-dialog-title", 3, "id", 4, "ngIf"], [1, "p-dialog-header-icons"], ["type", "button", "role", "button", 3, "ngClass", "click", "keydown.enter", 4, "ngIf"], [1, "p-dialog-title", 3, "id"], ["type", "button", "role", "button", 3, "click", "keydown.enter", "ngClass"], [1, "p-confirm-dialog-message", 3, "innerHTML"], [1, "p-dialog-footer"], ["type", "button", "pRipple", "", "pButton", "", 3, "label", "ngClass", "class", "click", 4, "ngIf"], ["type", "button", "pRipple", "", "pButton", "", 3, "click", "label", "ngClass"], ["class", "p-button-icon-left", 4, "ngIf"], [3, "class", 4, "ngIf"], [3, "styleClass", 4, "ngIf"], [3, "styleClass"], [1, "p-button-icon-left"]],
    template: function ConfirmDialog_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef(_c1);
        i0.ɵɵtemplate(0, ConfirmDialog_div_0_Template, 2, 4, "div", 2);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.maskVisible);
      }
    },
    dependencies: () => [i2.NgClass, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, i3.ButtonDirective, i4.Ripple, TimesIcon, CheckIcon],
    styles: ["@layer primeng{.p-dialog-mask{position:fixed;top:0;left:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center;pointer-events:none}.p-dialog-mask.p-component-overlay{pointer-events:auto}.p-dialog{display:flex;flex-direction:column;pointer-events:auto;max-height:90%;transform:scale(1);position:relative}.p-dialog-content{overflow-y:auto;flex-grow:1}.p-dialog-header{display:flex;align-items:center;justify-content:space-between;flex-shrink:0}.p-dialog-draggable .p-dialog-header{cursor:move}.p-dialog-footer{flex-shrink:0}.p-dialog .p-dialog-header-icons{display:flex;align-items:center}.p-dialog .p-dialog-header-icon{display:flex;align-items:center;justify-content:center;overflow:hidden;position:relative}.p-fluid .p-dialog-footer .p-button{width:auto}.p-dialog-top .p-dialog,.p-dialog-bottom .p-dialog,.p-dialog-left .p-dialog,.p-dialog-right .p-dialog,.p-dialog-top-left .p-dialog,.p-dialog-top-right .p-dialog,.p-dialog-bottom-left .p-dialog,.p-dialog-bottom-right .p-dialog{margin:.75rem;transform:translateZ(0)}.p-dialog-maximized{-webkit-transition:none;transition:none;transform:none;width:100vw!important;height:100vh!important;top:0!important;left:0!important;max-height:100%;height:100%}.p-dialog-maximized .p-dialog-content{flex-grow:1}.p-dialog-left{justify-content:flex-start}.p-dialog-right{justify-content:flex-end}.p-dialog-top{align-items:flex-start}.p-dialog-top-left{justify-content:flex-start;align-items:flex-start}.p-dialog-top-right{justify-content:flex-end;align-items:flex-start}.p-dialog-bottom{align-items:flex-end}.p-dialog-bottom-left{justify-content:flex-start;align-items:flex-end}.p-dialog-bottom-right{justify-content:flex-end;align-items:flex-end}.p-dialog .p-resizable-handle{position:absolute;font-size:.1px;display:block;cursor:se-resize;width:12px;height:12px;right:1px;bottom:1px}.p-confirm-dialog .p-dialog-content{display:flex;align-items:center}}\n"],
    encapsulation: 2,
    data: {
      animation: [trigger('animation', [transition('void => visible', [useAnimation(showAnimation)]), transition('visible => void', [useAnimation(hideAnimation)])])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfirmDialog, [{
    type: Component,
    args: [{
      selector: 'p-confirmDialog',
      template: `
        <div [class]="maskStyleClass" [ngClass]="getMaskClass()" *ngIf="maskVisible">
            <div
                [ngClass]="{ 'p-dialog p-confirm-dialog p-component': true, 'p-dialog-rtl': rtl }"
                [ngStyle]="style"
                [class]="styleClass"
                [@animation]="{ value: 'visible', params: { transform: transformOptions, transition: transitionOptions } }"
                (@animation.start)="onAnimationStart($event)"
                (@animation.done)="onAnimationEnd($event)"
                role="alertdialog"
                *ngIf="visible"
                [attr.aria-labelledby]="ariaLabelledBy"
                [attr.aria-modal]="true"
            >
                <ng-container *ngIf="headlessTemplate; else notHeadless">
                    <ng-container *ngTemplateOutlet="headlessTemplate; context: { $implicit: confirmation }"></ng-container>
                </ng-container>
                <ng-template #notHeadless>
                    <div class="p-dialog-header" *ngIf="headerTemplate">
                        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                    </div>
                    <div class="p-dialog-header" *ngIf="!headerTemplate">
                        <span class="p-dialog-title" [id]="ariaLabelledBy" *ngIf="option('header')">{{ option('header') }}</span>
                        <div class="p-dialog-header-icons">
                            <button *ngIf="closable" type="button" role="button" [attr.aria-label]="closeAriaLabel" [ngClass]="{ 'p-dialog-header-icon p-dialog-header-close p-link': true }" (click)="close($event)" (keydown.enter)="close($event)">
                                <TimesIcon />
                            </button>
                        </div>
                    </div>
                    <div #content class="p-dialog-content">
                        <i [ngClass]="'p-confirm-dialog-icon'" [class]="option('icon')" *ngIf="!iconTemplate && option('icon')"></i>
                        <ng-container *ngIf="iconTemplate">
                            <ng-template *ngTemplateOutlet="iconTemplate"></ng-template>
                        </ng-container>
                        <span class="p-confirm-dialog-message" *ngIf="!messageTemplate" [innerHTML]="option('message')"></span>
                        <ng-container *ngIf="messageTemplate">
                            <ng-template *ngTemplateOutlet="messageTemplate; context: { $implicit: confirmation }"></ng-template>
                        </ng-container>
                    </div>
                    <div class="p-dialog-footer" *ngIf="footer || footerTemplate">
                        <ng-content select="p-footer"></ng-content>
                        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
                    </div>
                    <div class="p-dialog-footer" *ngIf="!footer && !footerTemplate">
                        <button
                            type="button"
                            pRipple
                            pButton
                            [label]="rejectButtonLabel"
                            (click)="reject()"
                            [ngClass]="'p-confirm-dialog-reject'"
                            [class]="option('rejectButtonStyleClass')"
                            *ngIf="option('rejectVisible')"
                            [attr.aria-label]="rejectAriaLabel"
                        >
                            <ng-container *ngIf="!rejectIconTemplate">
                                <i *ngIf="option('rejectIcon')" [class]="option('rejectIcon')"></i>
                                <TimesIcon *ngIf="!option('rejectIcon')" [styleClass]="'p-button-icon-left'" />
                            </ng-container>
                            <span *ngIf="rejectIconTemplate" class="p-button-icon-left">
                                <ng-template *ngTemplateOutlet="rejectIconTemplate"></ng-template>
                            </span>
                        </button>
                        <button
                            type="button"
                            pRipple
                            pButton
                            [label]="acceptButtonLabel"
                            (click)="accept()"
                            [ngClass]="'p-confirm-dialog-accept'"
                            [class]="option('acceptButtonStyleClass')"
                            *ngIf="option('acceptVisible')"
                            [attr.aria-label]="acceptAriaLabel"
                        >
                            <ng-container *ngIf="!acceptIconTemplate">
                                <i *ngIf="option('acceptIcon')" [class]="option('acceptIcon')"></i>
                                <CheckIcon *ngIf="!option('acceptIcon')" [styleClass]="'p-button-icon-left'" />
                            </ng-container>
                            <span *ngIf="acceptIconTemplate" class="p-button-icon-left">
                                <ng-template *ngTemplateOutlet="acceptIconTemplate"></ng-template>
                            </span>
                        </button>
                    </div>
                </ng-template>
            </div>
        </div>
    `,
      animations: [trigger('animation', [transition('void => visible', [useAnimation(showAnimation)]), transition('visible => void', [useAnimation(hideAnimation)])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-dialog-mask{position:fixed;top:0;left:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center;pointer-events:none}.p-dialog-mask.p-component-overlay{pointer-events:auto}.p-dialog{display:flex;flex-direction:column;pointer-events:auto;max-height:90%;transform:scale(1);position:relative}.p-dialog-content{overflow-y:auto;flex-grow:1}.p-dialog-header{display:flex;align-items:center;justify-content:space-between;flex-shrink:0}.p-dialog-draggable .p-dialog-header{cursor:move}.p-dialog-footer{flex-shrink:0}.p-dialog .p-dialog-header-icons{display:flex;align-items:center}.p-dialog .p-dialog-header-icon{display:flex;align-items:center;justify-content:center;overflow:hidden;position:relative}.p-fluid .p-dialog-footer .p-button{width:auto}.p-dialog-top .p-dialog,.p-dialog-bottom .p-dialog,.p-dialog-left .p-dialog,.p-dialog-right .p-dialog,.p-dialog-top-left .p-dialog,.p-dialog-top-right .p-dialog,.p-dialog-bottom-left .p-dialog,.p-dialog-bottom-right .p-dialog{margin:.75rem;transform:translateZ(0)}.p-dialog-maximized{-webkit-transition:none;transition:none;transform:none;width:100vw!important;height:100vh!important;top:0!important;left:0!important;max-height:100%;height:100%}.p-dialog-maximized .p-dialog-content{flex-grow:1}.p-dialog-left{justify-content:flex-start}.p-dialog-right{justify-content:flex-end}.p-dialog-top{align-items:flex-start}.p-dialog-top-left{justify-content:flex-start;align-items:flex-start}.p-dialog-top-right{justify-content:flex-end;align-items:flex-start}.p-dialog-bottom{align-items:flex-end}.p-dialog-bottom-left{justify-content:flex-start;align-items:flex-end}.p-dialog-bottom-right{justify-content:flex-end;align-items:flex-end}.p-dialog .p-resizable-handle{position:absolute;font-size:.1px;display:block;cursor:se-resize;width:12px;height:12px;right:1px;bottom:1px}.p-confirm-dialog .p-dialog-content{display:flex;align-items:center}}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i1.ConfirmationService
  }, {
    type: i0.NgZone
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i1.PrimeNGConfig
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], {
    header: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    message: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    maskStyleClass: [{
      type: Input
    }],
    acceptIcon: [{
      type: Input
    }],
    acceptLabel: [{
      type: Input
    }],
    closeAriaLabel: [{
      type: Input
    }],
    acceptAriaLabel: [{
      type: Input
    }],
    acceptVisible: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    rejectIcon: [{
      type: Input
    }],
    rejectLabel: [{
      type: Input
    }],
    rejectAriaLabel: [{
      type: Input
    }],
    rejectVisible: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    acceptButtonStyleClass: [{
      type: Input
    }],
    rejectButtonStyleClass: [{
      type: Input
    }],
    closeOnEscape: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    dismissableMask: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    blockScroll: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    rtl: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    closable: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    appendTo: [{
      type: Input
    }],
    key: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    baseZIndex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    transitionOptions: [{
      type: Input
    }],
    focusTrap: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    defaultFocus: [{
      type: Input
    }],
    breakpoints: [{
      type: Input
    }],
    visible: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    onHide: [{
      type: Output
    }],
    footer: [{
      type: ContentChild,
      args: [Footer]
    }],
    contentViewChild: [{
      type: ViewChild,
      args: ['content']
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class ConfirmDialogModule {
  static ɵfac = function ConfirmDialogModule_Factory(t) {
    return new (t || ConfirmDialogModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ConfirmDialogModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, ButtonModule, RippleModule, TimesIcon, CheckIcon, ButtonModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfirmDialogModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ButtonModule, RippleModule, TimesIcon, CheckIcon],
      exports: [ConfirmDialog, ButtonModule, SharedModule],
      declarations: [ConfirmDialog]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ConfirmDialog, ConfirmDialogModule };
